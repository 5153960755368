import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { LayoutService } from '@app/layout/layout.service';
import { PageLayout as PageLayoutEnum } from '@app/layout/layout.service';
import { MessagingService } from '@app/services/messaging.service';
import { getMessaging, onMessage } from 'firebase/messaging';
import { messagingInstance$ } from '@angular/fire/messaging';
import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'prowodo';

  PageLayout = PageLayoutEnum;

  constructor(
    private contexts: ChildrenOutletContexts,
    public layoutService: LayoutService,
    private firebaseMessagingService: MessagingService,
    readonly swPush: SwPush // private angularFireMessaging: AngularFireMessaging
  ) {
    // this.firebaseMessagingService.receiveMessage();
  }

  ngOnInit() {
    onMessage(
      this.firebaseMessagingService.firebaseMessaggingApp,
      (payload) => {
        console.log('Message received. webpush firebase', payload);
        // const img = "/to-do-notifications/img/icon-128.png";
        // const text = `HEY! Your task "${title}" is now overdue.`;

        let option: any = {
          body: payload.notification?.body || '',
          // icon: img
        };
        if (payload.notification?.image) {
          option['image'] = payload.notification.image;
        }
        if (payload.notification?.icon) {
          option['icon'] = payload.notification.icon;
        }

        // this.swPush.messages.subscribe((message) => {
        //   console.log('Message received. webpush:', message);
        // });
        const notification = new Notification(
          payload.notification?.title || 'Prowodo Notification',
          option
        );

        notification.onclick = (event) => {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab

          if (payload?.fcmOptions) {
            let fcmOptions = payload.fcmOptions;
            if (fcmOptions?.link) {
              notification.onclick = () =>
                window.open(fcmOptions.link, '_blank');
              // window.open(payload.notification['click_action'] || '/', '_blank');
              notification.close();
            }
          }
        };

        // document.addEventListener(
        //   "visibilitychange", () => {
        //     if (document.visibilityState === "visible") {
        //       // The tab has become visible so clear the now-stale Notification.
        //       notification.close();
        //     }
        //   }
        // );
      }
    );
    // let messaging = this.firebaseMessagingService.firebaseMessaggingApp
    // messagingInstance$.subscribe((messaging) => {
    // // this.angularFireMessaging.messaging.subscribe((messaging) => {
    //   messaging.onMessage((message) => {
    //     console.log('Message received fCM:', message);
    //     // Handle the received message here
    //   });
    // });
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }
}
